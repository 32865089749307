import React from 'react';
import Layout from '../components/layout';
import HelmetComponent from '../components/helmetcomponent';

import ImgMom from '../img/img-sponsor-mom.png';

export default function Template({ data }) {

  return (
    <Layout nav="colored-nav">
        <HelmetComponent title="Thank you!" description="Thank you so much for your support!" />
        <div>
            <section id="thanks" className="mt130">
                <div className="container">
                    <div className="row mb40">
                        <div className="col-md-12 text-center">
                            <h2 className="f48">Thank you so much for your support!</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 text-center">
                            <img src={ImgMom} alt="thanks" width="50%"/>
                        </div>
                    </div>
                </div>
            </section>
        </div>
  
    </Layout>
  );
}
